<template>
  <div class="header-content">
    <div class="header-content-in">
      <img class="header-logo" src="@images/yxp_logo.png" @click="logoClick">
      <div class="header-nav">
        <div
          class="header-nav-item"
          v-for="(item,index) in navData"
          :key="index"
          @click="clickItem(index,item.callback,item.name)"
          :class="(currentNavIndex===index)?'header-nav-item-active':''"
        >{{item.name}}</div>
      </div>
      <div class="login-btn" @click="login">登录</div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'headerBar',
    props: {
        navData: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data () {
        return {
            currentNavIndex: 0,
        };
    },
    watch: {
        '$route': {
            handler (val) {
                switch (val.name) {
                    case constants.constants.route.INDEX.name: {
                        this.currentNavIndex = constants.constants.route.INDEX.index;
                        break;
                    }
                    // case constants.constants.route.SOLUTION.name: {
                    //     this.currentNavIndex = constants.constants.route.SOLUTION.index;
                    //     break;
                    // }
                    case constants.constants.route.SCHOOL.name: {
                        this.currentNavIndex = constants.constants.route.SCHOOL.index;
                        break;
                    }
                    case constants.constants.route.TEACHING.name: {
                        this.currentNavIndex = constants.constants.route.TEACHING.index;
                        break;
                    }
                    case constants.constants.route.PREPARELESSON.name: {
                        this.currentNavIndex = constants.constants.route.PREPARELESSON.index;
                        break;
                    }
                    case constants.constants.route.DOWNLOAD.name: {
                        this.currentNavIndex = constants.constants.route.DOWNLOAD.index;
                        break;
                    }
                    case constants.constants.route.PRODUCT.name: {
                        this.currentNavIndex = constants.constants.route.PRODUCT.index;
                        break;
                    }
                    case constants.constants.route.ABOUT_INDEX.name: {
                        this.currentNavIndex = constants.constants.route.ABOUT_INDEX.index;
                        break;
                    }
                    case constants.constants.route.JOIN.name: {
                        this.currentNavIndex = constants.constants.route.JOIN.index;
                        break;
                    }
                }
            },
            immediate: true,
        },
    },
    mounted () {

    },
    methods: {
        clickItem (index, callback, name) {
            if (name !== '下载') {
                this.currentNavIndex = index;
            }
            callback();
        },
        login () {
            console.log(this.$store);
            // const host = 'proj289test.anoah.com';
            // let url = process.env.NODE_ENV === 'development' ? 'https:' + host : '..';
            // window.location.href = `${url}/yxp-login/`;
            window.open('https://youxueketang.anoah.com/yxp-login/#/index');
        },
        logoClick () {
            this.$router.replace({
                name: 'index',
            });
        },
    },
    destroyed () {

    },
};
</script>

<style lang="scss" rel="stylesheet/scss">
  @import "@style/variable.scss";

  .header-content {
    width: 100%;
    height: $header-height;
    display: flex;
    min-width: $screen-min-width;
    justify-content: center;
    background: #ffffff;
    border-bottom: 1px solid #ebeef3;
  }

  .header-content-in {
    height: $header-height;
    display: flex;
    align-items: center;
    width: $screen-min-width;
    position: relative;
    padding: 0 20px;
  }

  .header-logo {
    width: 120px;
    height: 50px;
    /*margin-left: 205px;*/
    margin-right: 40px;
    /*margin-left: 14%;*/
    /*margin-right: 7%;*/
    cursor: pointer;
  }

  .header-nav {
    height: 100%;
    display: flex;
    /*width: 34.7%;*/
    width: 700px;
  }

  .header-nav-item {
    // flex: 1;
    padding: 0 18px 4px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    // padding-bottom: 4px;
  }

  .header-nav-item:hover {
    color: #4278E6;
  }

  .header-nav-item-active {
    color: #4278E6;
  }

  .header-nav-item-active:after {
    content: '';
    width: 46px;
    height: 2px;
    background: #4278E6;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .login-btn {
    width:80px;
    height:40px;
    background:rgba(61,154,255,1);
    border-radius:4px;
    color: #ffffff;
    font-size:16px;
    line-height:40px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 30px;
  }
</style>
