// const yxpapi = window.yxpapi;
// const store = yxpapi.store;

const index = () =>
    import(/* webpackChunkName: "index" */ '@modules/index/index.vue');
const download = () =>
    import(/* webpackChunkName: "download" */ '@modules/download/download.vue');
const downloadPc = () =>
    import(/* webpackChunkName: "downloadPc" */ '@modules/download/pc.vue');
const downloadPhone = () =>
    import(/* webpackChunkName: "downloadPhone" */ '@modules/download/phone.vue');
const aboutIndex = () =>
    import(/* webpackChunkName: "aboutIndex" */ '@modules/about/index.vue');
const solution = () =>
    import(/* webpackChunkName: "solution" */ '@modules/solution/index.vue');
const joinUs = () =>
    import(/* webpackChunkName: "joinUs" */ '@modules/join/index.vue');
const school = () =>
    import(/* webpackChunkName: "school" */ '@modules/school/index.vue');
const teaching = () =>
    import(/* webpackChunkName: "teaching" */ '@modules/teaching/index.vue');
const prepareLesson = () =>
    import(/* webpackChunkName: "prepareLesson" */ '@modules/prepareLesson/index.vue');
const product = () =>
    import(/* webpackChunkName: "product" */ '@modules/product/index.vue');
const AISmartRoom = () =>
    import(/* webpackChunkName: "AISmartRoom" */ '@modules/AISmartRoom/index.vue');
let router = new window.VueRouter({
    // mode: 'hash',
    routes: [
        {
            path: '*',
            redirect: '/',
        },
        {
            name: 'index',
            path: '/',
            component: index,
        },
        {
            name: 'solution',
            path: '/solution',
            component: solution,
        },
        {
            name: 'download',
            path: '/download',
            component: download,
            children: [
                {
                    name: 'downloadPc',
                    path: 'pc',
                    component: downloadPc,
                },
                {
                    name: 'downloadPhone',
                    path: 'phone',
                    component: downloadPhone,
                },
            ],
        },
        {
            name: 'aboutIndex',
            path: '/aboutUs',
            component: aboutIndex,
        },
        {
            name: 'join',
            path: '/joinUs',
            component: joinUs,
        },
        {
            name: 'School',
            path: '/school',
            component: school,
        },
        {
            name: 'Teaching',
            path: '/teaching',
            component: teaching,
        },
        {
            name: 'PrepareLesson',
            path: '/prepareLesson',
            component: prepareLesson,
        },
        {
            name: 'Product',
            path: '/product',
            component: product,
        },
        {
            name: 'AISmartRoom',
            path: '/AISmartRoom',
            component: AISmartRoom,
        },

    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

export default router;
