import App from './App.vue';
import router from './router';
import './store';
import './style/common.scss';

window.Vue.config.productionTip = false;
window.Vue.config.devtools = process.env.NODE_ENV === 'development';
new window.Vue({
    router,
    store: window.yxpapi.store,
    render: h => h(App),
}).$mount('#yxp_website');
