<template>
  <div id="yxp_website" :class="[
          {'download': $route.name.includes('download')},
          {'AISmartRoom': $route.name.includes('AISmartRoom')},
      ]">
    <header v-if="showHeaderAndFooter" class="header-out">
      <HeaderBar
        :navData="navData"
      ></HeaderBar>
    </header>
    <section class="content-out">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
      <footer v-if="showHeaderAndFooter" class="footer-out">
        <FooterBar></FooterBar>
      </footer>
    </section>
  </div>
</template>

<script>
import HeaderBar from '@components/header/header.vue';
import FooterBar from '@components/footer/footer.vue';
export default {
    name: 'App',
    data () {
        return {
            navData: [
                {
                    name: '首页',
                    callback: () => {
                        this.$router.replace({
                            name: 'index',
                        });
                    },
                },
                // {
                //     name: '智慧课堂',
                //     callback: () => {
                //         this.$router.replace({
                //             name: 'solution',
                //         });
                //     },
                // },
                {
                    name: '智慧校园',
                    callback: () => {
                        this.$router.replace({
                            name: 'School',
                        });
                    },
                },
                {
                    name: '智慧教学',
                    callback: () => {
                        this.$router.replace({
                            name: 'Teaching',
                        });
                    },
                },
                {
                    name: '备授课云平台',
                    callback: () => {
                        this.$router.replace({
                            name: 'PrepareLesson',
                        });
                    },
                },
                {
                    name: '产品',
                    callback: () => {
                        this.$router.replace({
                            name: 'Product',
                        });
                    },
                },
                {
                    name: '下载',
                    callback: () => {
                        // this.$router.replace({
                        //     name: 'download',
                        // });
                        const { href } = this.$router.resolve({
                            name: `downloadPc`,

                        });
                        window.open(href, '_blank');
                    },
                },
                {
                    name: '合作',
                    callback: () => {
                        this.$router.replace({
                            name: 'join',
                        });
                    },
                },
                {
                    name: '关于',
                    callback: () => {
                        this.$router.replace({
                            name: 'aboutIndex',
                        });
                    },
                },
            ],
        };
    },
    computed: {
        showHeaderAndFooter () {
            return !['download', 'AISmartRoom'].includes(this.$route.name);
        },
    },
    mounted () {

    },
    methods: {

    },
    destroyed () {

    },
    components: {
        HeaderBar,
        FooterBar,
    },
};
</script>

<style lang="scss" rel="stylesheet/scss">
  @import "@style/variable.scss";
  #yxp_website {
    width: 100%;
    height: 100%;
    padding-top: $header-height;
    min-width: $screen-min-width;
    position: relative;
  }

  .header-out {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 100 !important;
  }

  .content-out {
    width: 100%;
    min-height: 100%;
    padding-bottom: $footer-height;
    position: relative;
  }

  .footer-out {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

</style>
