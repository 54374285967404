/**
 * Created by wangyushen on 2020/3/9.
 */

export const constants = {
    route: {
        INDEX: {
            name: 'index',
            index: 0,
        },
        // SOLUTION: {
        //     name: 'solution',
        //     index: 1,
        // },
        SCHOOL: {
            name: 'School',
            index: 1,
        },
        TEACHING: {
            name: 'Teaching',
            index: 2,
        },
        PREPARELESSON: {
            name: 'PrepareLesson',
            index: 3,
        },
        PRODUCT: {
            name: 'Product',
            index: 4,
        },
        DOWNLOAD: {
            name: 'download',
            index: 5,
        },
        JOIN: {
            name: 'join',
            index: 6,
        },
        ABOUT_INDEX: {
            name: 'aboutIndex',
            index: 7,
        },
    },
};
