<template>
  <div class="footer-content">
    <div class="footer-content-in">
      <table>
        <tr class="footer-content-table-top">
          <td class="footer-content-table-left">
            <div class="footer-content-text-big">公司</div>
          </td>
          <td class="footer-content-table-middle">
            <div class="footer-content-text-big">友情链接</div>
          </td>
          <td class="footer-content-table-right">
            <div class="footer-content-text-big">联系我们</div>
          </td>
        </tr>
        <tr class="footer-content-table-bottom">
          <td class="footer-content-table-left">
            <div class="footer-content-about">
              <div class="footer-content-text-middle" style="margin-bottom: 13px;color:#B4B4B4;">关于我们</div>
              <div class="footer-content-text-small" style="margin-bottom: 6px;color:#B4B4B4;">深圳市优学天下教育发展股份有限公司</div>
              <div class="footer-content-text-small" style="margin-bottom: 6px;color:#B4B4B4;">地址：深圳市福田区天安车公庙工业区天经大厦F3.8-82（AB座8楼）</div>
              <div class="footer-content-text-small" style="margin-bottom: 6px;color:#B4B4B4;">电话：0755—83432800</div>
              <div class="footer-content-text-small" style="margin-bottom: 6px;color:#B4B4B4;">深圳市优学天下教育发展股份有限公司成都分公司</div>
              <div class="footer-content-text-small" style="margin-bottom: 6px;color:#B4B4B4;">地址：成都市高新区天府大道中段1268号1栋（天府软件园E3座）8层1-12号</div>
              <div class="footer-content-text-small" style="color:#B4B4B4;">电话：028-85308888</div>
            </div>
          </td>
          <td class="footer-content-table-middle">
            <div class="footer-content-text-middle" style="margin-top: 16px;cursor: pointer;" @click="goToYxp">优学派官网</div>
          </td>
          <td class="footer-content-table-right">
            <div class="footer-content-contact">
              <img class="footer-content-contact-QR" src="@images/QR.png">
              <div class="footer-content-contact-text">
                <div class="footer-content-text-middle" style="margin-bottom: 16px">全国服务热线</div>
                <div class="footer-content-text-middle" style="margin-bottom: 73px">400-628-9898</div>
                <div class="footer-content-text-small">扫描二维码关注“优学派智慧课堂”</div>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <div class="footer-content-info">
        <div class="footer-content-text-small" style="color:#B4B4B4;width:100%;display: flex;justify-content: center;white-space: nowrap;">Copyright © 2009-2020 youxueketang.com. All rights Reserved</div>
        <div class="footer-content-text-small" style="margin-top: 8px;color:#B4B4B4;width:100%;display: flex;justify-content: center;white-space: nowrap;cursor: pointer;" @click="goToMIIT">粤ICP备05070472号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'footerBar',
    props: {

    },
    data () {
        return {

        };
    },
    mounted () {

    },
    methods: {
        goToYxp () {
            window.open('https://www.youxuepai.com');
        },
        goToMIIT () {
            window.open('http://beian.miit.gov.cn');
        },
    },
    destroyed () {

    },
};

</script>

<style lang="scss" rel="stylesheet/scss">
  @import "@style/variable.scss";

  .footer-content {
    width: 100%;
    height: $footer-height;
    background: #2E3032;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-content-in {
    width: 1100px;
    margin-bottom: 9px;
  }

  table, td, tr {
    vertical-align: top;
    border-bottom: solid 1px #4A4A4A;
    border-collapse: collapse;
    padding: 0;
    margin: 0;
  }

  .footer-content-table {
    width: 100%;
  }

  .footer-content-table-top {
    height: 24px;
    border-bottom: solid 1px #4A4A4A;
  }

  .footer-content-table-bottom {
    height: 168px;
    border-bottom: solid 1px #4A4A4A;
  }

  .footer-content-table-left {
    width: 516px;
    padding-left: 27px;
  }

  .footer-content-table-middle {
    width: 196px;
  }

  .footer-content-table-right {
    width: 388px;
  }

  .footer-content-text-big {
    font-size:16px;
    color:#ffffff;
    line-height:16px;
    white-space: nowrap;
  }

  .footer-content-text-middle {
    font-size:14px;
    color:#ffffff;
    line-height:14px;
    white-space: nowrap;
  }

  .footer-content-text-small {
    font-size:12px;
    color:#ffffff;
    line-height:12px;
    white-space: nowrap;
  }

  .footer-content-about {
    padding-top: 16px;
    padding-bottom: 23px;
  }

  .footer-content-contact {
    padding-top: 16px;
    padding-bottom: 23px;
    display: flex;
  }

  .footer-content-contact-QR {
    width: 129px;
    height: 129px;
  }

  .footer-content-contact-text {
    margin-left: 24px;
  }

  .footer-content-info {
    width: 100%;
    padding-top: 18px;
  }
</style>
